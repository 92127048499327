import React from "react";
import "./ComponentStyles.css"; // Import shared styles

const NoteSheet = ({ data, setData, showForm, setShowForm }) => {
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="component-card">
      <label className="checkbox-label">
        <input type="checkbox" disabled checked={data.poNumber !== "" && data.poDate !== ""} />
        <span></span> NoteSheet
      </label>
      <button className="fill-btn" onClick={() => setShowForm(true)}>Fill Details</button>

      {showForm && (
        <div className="form-container">
          <h3 className="form-title">NoteSheet Details</h3>
          <input type="text" name="poNumber" placeholder="PO Number" value={data.poNumber} onChange={handleChange} className="form-input" />
          <input type="date" name="poDate" placeholder="PO Date" value={data.poDate} onChange={handleChange} className="form-input" />
          <button className="save-btn" onClick={() => setShowForm(false)}>Save</button>
        </div>
      )}
    </div>
  );
};

export default NoteSheet;
