import React, { useState } from "react";
import * as XLSX from "xlsx"; // Import XLSX library
import "./ComponentStyles.css"; // Import shared styles

const MaterialConsumption = ({ data, setData, showForm, setShowForm,updateMeterCount }) => {
  const handleWorkOrderChange = (index, field, value) => {
    const updatedWorkOrders = [...data.workOrders];
    updatedWorkOrders[index][field] = value;
    setData({ ...data, workOrders: updatedWorkOrders });
  };



const handleFileUpload = (e) => {
  const file = e.target.files[0];

  if (file) {
    // Validate file type
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "xls" && fileExtension !== "xlsx") {
      alert("Invalid file type! Please upload an Excel file (.xls or .xlsx).");
      e.target.value = ""; // Clear the input field
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file); // ✅ Use `readAsArrayBuffer`

    reader.onload = async (event) => {
      const bufferArray = event.target.result;
      const workbook = XLSX.read(new Uint8Array(bufferArray), { type: "array" });

      // Get first sheet name
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      console.log("Full JSON Data:", jsonData); // Debugging: See full structure

      // Start filtering from row 5 onwards
      const filteredData = jsonData.slice(5);

      // Filter rows that have "APPLICATION NO" (`__EMPTY`) and "TOTAL AMOUNT" (`__EMPTY_14`)
      const filteredItems = filteredData.filter(row =>
        row["__EMPTY"] !== undefined && row["__EMPTY"] !== "" && // Must have an "APPLICATION NO"
        row["__EMPTY_14"] !== undefined && row["__EMPTY_14"] !== "" // Must have a "TOTAL AMOUNT"
      );

      const allMeterCount = filteredData.filter(row=> 
        row["__EMPTY"] !== undefined && row["__EMPTY"] !== ""
      ).length;


      console.log("ALL Meter count:",allMeterCount);
      console.log("Filtered Items:", filteredItems); // ✅ Log full details of filtered items
      console.log("Total Meters Count:", filteredItems.length); // ✅ Log total count



    const totalSERLMeter = filteredItems.reduce((sum, row) => {
        const value = parseFloat(row["__EMPTY_10"]); // Convert to number
        return sum + (isNaN(value) ? 0 : value); // Add if valid number
      }, 0);

      console.log("Total Meters Count:", filteredItems.length); // ✅ Log total meter count
      console.log("Total SE/RL Meter:", totalSERLMeter); // ✅ Log total SE/RL Meter count

      // ✅ Update state in NSCBilling
      updateMeterCount(filteredItems.length, totalSERLMeter);

      // ✅ Ensure the latest state is updated
      setData(prevData => ({
        ...prevData,
        meterDetails: file,
        filteredItems,
        totalSERLMeter,
        allMeterCount
      }));

    };
  }
};


const removeWorkOrder = (index) => {
    setData((prevData) => ({
      ...prevData,
      workOrders: prevData.workOrders.filter((_, i) => i !== index),
    }));
  };


  const addWorkOrder = () => {
    setData((prevData) => ({
      ...prevData,
      workOrders: [...prevData.workOrders, { workOrderNumber: "", workOrderDate: "" }],
    }));
  };


  
  return (
    <div className="component-card">
      <label className="checkbox-label">
        <input type="checkbox" disabled checked={data.workOrders[0].workOrderNumber !== "" && data.workOrders[0].workOrderDate !== ""} />
        <span></span> Material Consumption
      </label>
      <button className="fill-btn" onClick={() => setShowForm(true)}>Fill Details</button>

      {showForm && (
        <div className="form-container">
          <h3 className="form-title">Material Consumption Details</h3>
          {data.workOrders.map((order, index) => (
            <div key={index} className="workorder-inputs">
              <input type="text" placeholder="Work Order Number" value={order.workOrderNumber} onChange={(e) => handleWorkOrderChange(index, "workOrderNumber", e.target.value)} className="form-input" />
              <input type="date" placeholder="Work Order Date" value={order.workOrderDate} onChange={(e) => handleWorkOrderChange(index, "workOrderDate", e.target.value)} className="form-input" />
              {index > 0 && (
                <button className="remove-btn" onClick={() => removeWorkOrder(index)}>
                  Remove
                </button>
              )}
            </div>
          ))}
           <button className="add-btn" onClick={addWorkOrder}>
            + Add Work Order
          </button>
          <input type="file" onChange={handleFileUpload} className="file-input" accept=".xls,.xlsx" />
          {data.meterCount !== undefined && <p>Number of Meters: {data?.filteredItems?.length}</p>}
          <button className="save-btn" onClick={() => setShowForm(false)}>Save</button>
        </div>
      )}
    </div>
  );
};

export default MaterialConsumption;
