import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { collection, getFirestore, limit, orderBy, query, setDoc, doc, serverTimestamp } from "firebase/firestore";
import logo from "./letterpad.png"; // Replace with your actual logo path
import sign from "./signature.png";

const GenerateCLCBill = ({invoices,invoiceRef}) => {
    const [invNumber,setInvNumber] = useState();
    const [isSending, setIsSending] = useState(false); // State to track email sending
    const [message, setMessage] = useState("");

     useEffect(() => {
    
            if (invoices && invoices.length > 0) {
                const maxInvoiceNo = Math.max(...invoices.map(invoice => parseInt(invoice.invoiceNo, 10) || 0));
                setInvNumber(maxInvoiceNo + 1);
            } else {
                setInvNumber();
            }
        }, [invoices]);

    
  const invoiceData = [
    {
      description:
        "Memo No: CLCTA/APE/336/2022\nMonthy maintenance of Street lights at Calcutta Leather Complex",
      hsncode: "9987",
      quantity: "751",
      rate: "210",
      per: "Pole",
      amount: "157710.00",
      cgst: "14193.90",
      sgst: "14193.90",
      adjustment: "+0.20",
      totalAmount: "186098.00",
      amountWords: "Rupees One Lakh Eighty Six Thousand Ninety Eight only",
    },
    {
      description:
        "Order No:CLCTA/workorder/15380/electric maintenance.\nThere are 6 EPS, the following EPS are 1,2,3,4,5,7\n\nFor EPS Maintenance works are as follows :-\nLT Portion\n\n1) Maintenance of main panel.\n2) Maintenance of all panels for their respective pumps.\n3) Maintenance of all lights and fans.\n4) Maintenance of Capacitor Panel.\n",
      hsncode: "9954",
      quantity: "6",
      rate: "6000",
      per: "Job",
      amount: "36000.00",
      cgst: "3240.00",
      sgst: "3240.00",
      adjustment: "",
      totalAmount: "42480.00",
      amountWords: "Rupees Forty Two Thousand Four Hundred Eighty only",
    },
    {
      description:
        "OrderNo:CLCTA/workorder/15381/electric maintenance\nThere are 3 PPS, the following PPS are 2,3,4\n\nFor PPS Maintenance works are as follows :-\n\nLT Portion\n1) Maintenance of main panel.\n2) Maintenance of all panels for their respective pumps.\n3) Maintenance of all lights and fans.\n4) Maintenance of Capacitor Panel.\n",
      hsncode: "9954",
      quantity: "3",
      rate: "4000",
      per: "Job",
      amount: "12000.00",
      cgst: "1080.00",
      sgst: "1080.00",
      adjustment: "",
      totalAmount: "14160.00",
      amountWords: "Rupees Fourteen Thousand One Hundred Sixty only",
    },
    {
      description:
        "Work Order : Maintanace of T.E.S P.S plant No : CLCTA 1860/18/8/12\n\n1) H.T. Portion\nMaintanance of VCB servicing every three month.\n\n2) L.T Portion\nMaintanance of Main Panal, Machine Panal, Distribution Panalservicing every month.\n\n3) Lighting & Light Fitting\nMaintanace of street light, Indoor and outdoor Light fitting and general Electrification.",
      hsncode: "9954",
      quantity: "1",
      rate: "8000",
      per: "Job",
      amount: "8000.00",
      cgst: "720.00",
      sgst: "720.00",
      adjustment: "",
      totalAmount: "9440.00",
      amountWords: "Rupees Nine Thousand Four Hundred Forty only",
    },
  ];


  const sendDataToFirebase = async (currNumber,invoice) => {
    const formattedDate = new Date().toISOString().split("T")[0];
    try {
        await setDoc(doc(invoiceRef), {
            invoiceNo: `${currNumber}/2025-26`,
            user: "Irfan",
            date: formattedDate,
            company: "CLC Tanners Association-Monthly",
            amount: invoice.totalAmount, 
            createdAt: serverTimestamp(),
        });
    }
 catch (err) {
    console.log(err);
    alert("An error occurred..");
}
  }

  const generateAllInvoicesPDF = async () => {
    let currNumber = invNumber;
    const doc = new jsPDF();
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(today.getMonth() + 1)
  .toString()
  .padStart(2, "0")}/${today.getFullYear()}`;
  const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const formattedMonth = lastMonth.toLocaleString("en-US", { month: "long" });
const formattedYear = lastMonth.getFullYear();


    invoiceData.forEach((invoice, index) => {
        if (index > 0) doc.addPage();

    // Header Section with Logo
    const imgWidth = 180; // Full width of the page
    const imgHeight = 35; // Adjust the height proportionally
    doc.addImage(logo, "PNG", 15, 8, imgWidth, imgHeight); // Add logo

    // Title Section
    const titleYPosition = imgHeight + 10;
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    const text = "TAX INVOICE";
    const xPosition = 100; // Centered horizontally
    const yPosition = titleYPosition + 8; // Adjusted Y position
    doc.text(text, xPosition, yPosition, { align: "center" });

    // Header Grid Section
    const headerContent = [
      [
        {
          content: `A.P Enterprise\nP-300, Darga Road, Kolkata - 700017\nGSTIN/UIN: 19ASAFS7575C1Z5`,
          styles: { fontSize: 10, halign: "left" },
        },
        {
          content: `Invoice No: ${currNumber}/2025-26\nDated: ${formattedDate}`,
          styles: { fontSize: 10, halign: "left" },
        },
      ],
      [
        {
          content: `Consignee:\nCLC TANNERS' ASSOCIATION\nCalcutta Leather Complex, CTR,\nKaraidanga, South 24 Parganas\nGST: 19AACCC7323H2ZO`,
          styles: { fontSize: 10, halign: "left" },
        },
        {
          content: `Delivery Note:\nMode/Terms of Payment:\nBuyer's Order No.:\nDespatched Through:`,
          styles: { fontSize: 10, halign: "left" },
        },
      ],
    ];

   

    doc.autoTable({
      body: headerContent,
      theme: "grid",
      styles: {
        fontSize: 10,
        cellPadding: 2,
      },
      startY: titleYPosition + 12,
      columnStyles: {
        0: { cellWidth: 110 },
        1: { cellWidth: 70 },
      },
    });

    // Table Section
    const tableRows = [
      [
        "1",
        invoice.description,
        invoice.hsncode,
        invoice.quantity,
        invoice.rate,
        invoice.per,
        { content: invoice.amount, styles: { halign: "right" } },
      ],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      [
        "",
        "CGST 9%",
        "",
        "",
        "",
        "",
        { content: invoice.cgst, styles: { halign: "right" } },
      ],
      [
        "",
        "SGST 9%",
        "",
        "",
        "",
        "",
        { content: invoice.sgst, styles: { halign: "right" } },
      ],
      [
        "",
        invoice.adjustment ? "Adjustment (Round off)" : "",
        "",
        "",
        "",
        "",
        { content: invoice.adjustment, styles: { halign: "right" } },
      ],
      [
        {
          content: "Total",
          colSpan: 6,
          styles: { halign: "right", fontStyle: "bold" },
        },
        {
          content: invoice.totalAmount,
          styles: { halign: "right", fontStyle: "bold" },
        },
      ],
      [
        {
          content: `Amount Chargeable (in words)\n${invoice.amountWords}`,
          colSpan: 7,
          styles: { halign: "left", fontStyle: "bold" },
        },
      ],
      [
        {
          content: `This Bill is for the month ${formattedMonth}, ${formattedYear}\nCompany’s GSTIN/UIN : 19ASAFS7575C1Z5\n\nDeclaration:\nWe declare that this invoice shows the actual price of the goods\ndescribed and that all particulars are true and correct.`,
          styles: { halign: "left", fontSize: 10 },
          colSpan: 4,
        },
        {
          content: `Company’s Bank Details\nBank Name : Punjab National Bank\nAccount Number : 0377050011644\nBranch and IFSC Code : Darga Road, PUNB0037720`,
          styles: { halign: "left", fontSize: 10 },
          colSpan: 3,
        },
      ],
    ];

    doc.autoTable({
      head: [
        [
          "Sl No",
          "Description",
          "HSN/SAC",
          "Quantity",
          "Rate",
          "Per",
          "Amount",
        ],
      ],
      body: tableRows,
      startY: doc.lastAutoTable.finalY + 10,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 2 },
      headStyles: { fillColor: [230, 230, 230], textColor: 0 },
    });

    const lastYPosition = doc.lastAutoTable.finalY - 14;

    const signWidth = 32; // Full width of the page
    const signHeight = 32; // Adjust the height proportionally
    doc.addImage(sign, "PNG", 110, lastYPosition, signWidth, signHeight);


    sendDataToFirebase(currNumber,invoice);

    currNumber += 1;
});
    // Save the PDF
    doc.save(`All_Invoices.pdf`);

    const pdfBlob = doc.output("blob");

  // Create a FormData object to send the file to the server
  const formData = new FormData();
  formData.append("pdf", pdfBlob, `All_Invoices_${formattedDate}.pdf`);

  // Send the PDF to the server

   
  try {
    setIsSending(true); // Start animation
    setMessage("");
    const response = await fetch("https://apenterprise-server.onrender.com/api/send-clc-invoice", {
      method: "POST",
      body: formData,
    });

    const result = await response.json();
    if (response.ok) {
        setMessage("PDF sent successfully via email!");
    } else {
        setMessage(`Failed to send PDF: ${result.message}`);
    }
  } catch (error) {
    console.error("Error sending PDF:", error);
    setMessage("An error occurred while sending the PDF.");
  }finally {
    setIsSending(false); // Stop animation
  }
  };



return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <button
        onClick={generateAllInvoicesPDF}
        style={{
          padding: "10px 20px",
          backgroundColor: isSending ? "#ccc" : "#0e8c27",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: isSending ? "not-allowed" : "pointer",
          position: "relative",
        }}
        disabled={isSending}
      >
        {isSending ? (
          <>
            <div
              className="spinner"
              style={{
                width: "20px",
                height: "20px",
                border: "3px solid #fff",
                borderTop: "3px solid #0e8c27",
                borderRadius: "50%",
                animation: "spin 1s linear infinite",
                display: "inline-block",
                marginRight: "10px",
                position: "absolute",
                top: "30%",
                left: "110%",
                transform: "translate(-50%, -50%)",
              }}
            />
            Sending...
          </>
        ) : (
          "Generate CLC Bills"
        )}
      </button>

      {message && (
        <p
          style={{
            marginTop: "10px",
            color: message.includes("successfully") ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

// Add spinner animation CSS
const style = document.createElement("style");
style.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);



export default GenerateCLCBill;
