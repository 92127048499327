import React, { useState } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import NoteSheet from "./NoteSheet";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from './logo.png'
import header from './header.png'
import MaterialConsumption from "./MaterialConsumption";
import ActualBill from "./ActualBill";
import BillReverseCopy from "./BillReverseCopy";
import billTemplate from './billTemplate.jpg';
import mcsTemplate from './mcs.jpg';
import BillTemplate from "./BillTemplate"; // PDF Template
import "./BillingSystem.css";
import DashboardHeader from "../../Admin/Dashboard/DashboardHeader"; // Import CSS for styling
//import OswaldBold from './Oswald_Bold';

const NSCBilling = () => {
  const [selectedComponents, setSelectedComponents] = useState({
    notesheet: false,
    materialConsumption: false,
    actualBill: false,

    billReverseCopy: false,
  });

  const [data, setData] = useState({
    poNumber: "",
    poDate: "",
    workOrders: [{ workOrderNumber: "", workOrderDate: "" }],
    meterDetails: null,
    meterCount: 0,
    totalSERLMeter:0,
    allMeterCount:0,
  });


//   const addWorkOrder = () => {
//     setData((prevData) => ({
//       ...prevData,
//       workOrders: [...prevData.workOrders, { workOrderNumber: "", workOrderDate: "" }],
//     }));
//   };

//   const removeWorkOrder = (index) => {
//     setData((prevData) => ({
//       ...prevData,
//       workOrders: prevData.workOrders.filter((_, i) => i !== index),
//     }));
//   };

  const handleWorkOrderChange = (index, field, value) => {
    const updatedWorkOrders = [...data.workOrders];
    updatedWorkOrders[index][field] = value;
    setData((prevData) => ({
      ...prevData,
      workOrders: updatedWorkOrders,
    }));
  };




const updateMeterCount = (count, totalSERLMeter) => {
    console.log("Updating meter count to:", count); // Debugging log
    console.log("Updating SE/RL Meter count to:", totalSERLMeter); // Debugging log
  
    setData(prevData => ({
      ...prevData,
      meterCount: count,
      totalSERLMeter
    }));
  };
  
  

  const [showNoteSheetForm, setShowNoteSheetForm] = useState(false);
  const [showMaterialConsumptionForm, setShowMaterialConsumptionForm] = useState(false);
  const [showBill, setShowBill] = useState(false);

  // Function to check if all data fields are filled
  const isAllDataFilled = () => {
    return (
      data.poNumber !== "" &&
      data.poDate !== "" &&
      data.workOrders.every(
        (order) => order.workOrderNumber !== "" && order.workOrderDate !== ""
      ) &&
      data.meterDetails !== null
    );
  };

  // Function to save form data and tick checkboxes
  const saveForms = () => {
    if (isAllDataFilled()) {
      setSelectedComponents({
        notesheet: true,
        materialConsumption: true,
        actualBill: true,
        billReverseCopy: true,
      });
      setShowNoteSheetForm(false);
      setShowMaterialConsumptionForm(false);
    } else {
      alert("Please fill in all the details before saving.");
    }
  };

  // Function to reset all forms and uncheck all checkboxes
  const resetForms = () => {
    setSelectedComponents({
      notesheet: false,
      materialConsumption: false,
      actualBill: false,
      billReverseCopy: false,
    });

    setData({
      poNumber: "",
      poDate: "",
      workOrders: [{ workOrderNumber: "", workOrderDate: "" }],
      meterDetails: null,
    });

    setShowBill(false); // Hide bill preview after reset
  };


  const formatDate = (dateString) => {
    if (!dateString) return "DD.MM.YYYY"; // Default placeholder
  
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${date.getFullYear()}`;
  };

  const numToWords = (num) => {
    if (isNaN(num)) return "Invalid number";
  
    // Convert number to two parts: Rupees and Paisa
    const rupees = Math.floor(num); // Integer part
    const paisa = Math.round((num - rupees) * 100); // Decimal part
  
    // Arrays for words conversion
    const a = [
      "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
      "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
      "seventeen", "eighteen", "nineteen"
    ];
    const b = [
      "", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy",
      "eighty", "ninety"
    ];
  
    const convert = (n) => {
      if (n < 20) return a[n];
      if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? " " + a[n % 10] : "");
      if (n < 1000)
        return a[Math.floor(n / 100)] + " hundred" + (n % 100 ? " " + convert(n % 100) : "");
      if (n < 100000)
        return convert(Math.floor(n / 1000)) + " thousand" + (n % 1000 ? " " + convert(n % 1000) : "");
      if (n < 10000000)
        return convert(Math.floor(n / 100000)) + " lakh" + (n % 100000 ? " " + convert(n % 100000) : "");
      return convert(Math.floor(n / 10000000)) + " crore" + (n % 10000000 ? " " + convert(n % 10000000) : "");
    };
  
    // Convert Rupees and Paisa to words
    let words = "Rupees " + convert(rupees);
    if (paisa > 0) {
      words += " and " + convert(paisa) + " paisa";
    }
    words += " only";
  
    return words.charAt(0).toUpperCase() + words.slice(1); // Capitalize first letter
  };



  const wrapText = (doc, text, startX, maxX, startY, lineHeight) => {
    const maxWidth = maxX - startX; // Calculate available width (82 - 76 = 6)
    
    // Split text into smaller lines that fit within maxWidth
    const textLines = doc.splitTextToSize(text, maxWidth);
    
    let y = startY;
    
    textLines.forEach((line) => {
      doc.text(line, startX, y); // Print text at (76, y)
      y += lineHeight; // Move to the next line
    });
  };


  const formatWorkOrders = (workOrders) => {
    const orderNumbers = workOrders.map(order => order.workOrderNumber).filter(Boolean).join("/");
    const orderDates = workOrders.map(order => formatDate(order.workOrderDate)).filter(Boolean).join("/");
    return { orderNumbers, orderDates };
  };
  
  // Extract formatted work orders
  const { orderNumbers, orderDates } = formatWorkOrders(data.workOrders);
  


const generateNoteSheetPDF = (data) => {
  //const doc = new jsPDF();

  const doc = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });
  const today = new Date();
  const formattedDate = formatDate(today);

  
  doc.addImage(logo, "PNG", 15, 10, 25, 25);

 
  doc.setLineWidth(0.3); // **Set Border Thickness Dark Black**
  doc.line(43, 0, 43, 297); // Full height vertical line

  // **Move All Content to the Right of the Line**
  const contentX = 50;

  // **Header Logo**
  doc.addImage(header, "PNG", 52, 10, 150, 20);

  // **Title "NOTE SHEET"**
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("NOTE SHEET", contentX + 60, 35);
  const textWidth = doc.getTextWidth("NOTE SHEET");
  doc.line(contentX + 60, 36, contentX + 60 + textWidth, 36); // Underline

  // **Subject Line**
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(
    `Sub: Submission of 1st & Final Bill for New Service Connection in favor of A.P. ENTERPRISE, vendor code - 512730 under Minakhan CCC.`,
    contentX,
    45,
    { maxWidth: 145 }
  );

  // **Reference P.O Details**
  doc.text("Ref: P.O details are below:", contentX, 60);

  // **Table Header & Body with Black Border & White Text**
  const tableHeaders = [
    [
      { content: "Sl no", styles: { halign: "center", fillColor: [255,255,255], textColor: [0, 0, 0] } },
      { content: "P.O Number", styles: { halign: "center", fillColor: [255,255,255], textColor: [0, 0, 0] } },
      { content: "Date of P.O", styles: { halign: "center", fillColor: [255,255,255], textColor: [0, 0, 0] } },
      { content: "Remarks", styles: { halign: "center", fillColor: [255,255,255], textColor: [0, 0, 0] } },
    ],
  ];

  const tableData = [
    [
      { content: "1", styles: { halign: "center", lineWidth: 0.7 } },
      { content: data?.poNumber , styles: { fontStyle: "bold", halign: "center", lineWidth: 0.7 } },
      { content: formatDate(data?.poDate), styles: { halign: "center", lineWidth: 0.7 } },
      { content: "" , styles: { fontStyle: "italic", textColor: [0, 0, 255], lineWidth: 0.7 } }, // Blue handwritten-style text
    ],
  ];

  doc.autoTable({
    head: tableHeaders,
    body: tableData,
    startY: 65,
    theme: "grid",
    styles: { fontSize: 10, cellPadding: 3, lineWidth: 0.5 }, // **Black Borders**
    margin: { left: contentX },
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 50 },
      2: { cellWidth: 40 },
      3: { cellWidth: 40 },
    },
  });

  let yPositions = doc.lastAutoTable.finalY + 10;
  doc.text(
    "Please find enclosed herewith the bills of above P.O against NSC under Minakhan CCC as submitted by A. P. ENTERPRISE for necessary course of action your end.",
    contentX,
    yPositions,
    { maxWidth: 140 }
  );

  // **Deduction Note**
  let yPosition = yPositions + 10;
  doc.text(
    "The bill shall be deducted from each consumer to Rs. 152.92/- against the item listed below due to non-supply materials as observed on sample inspection.",
    contentX,
    yPosition,
    { maxWidth: 140 }
  );

  // **Deduction Table**
  yPosition += 15;




doc.text("i)",50,yPosition);
doc.text("Reel insulator(small)- Rs 75.00/-",55,yPosition);
yPosition+=5;
doc.text("ii)",50,yPosition);
doc.text("CI Reel Insulator - Rs 31.05/-",55,yPosition);
yPosition+=5;
doc.text("iii)",50,yPosition);
doc.text("Brass Earth Terminal - Rs 7.50/-",55,yPosition);
yPosition+=5;
doc.text("iv)",50,yPosition);
doc.text("GI Hook Spike - Rs 21.00/-",55,yPosition);
yPosition+=5;
doc.text("v)",50,yPosition);
doc.text("LT Guy Insulator - Rs 18.37/-",55,yPosition);


yPosition+=10;
doc.text("Total = Rs 152.92/-",65,yPosition);
yPosition+=10;

doc.text("Total Bill Amount - Rs. 1,28,977.78/-(Include GST)",50,yPosition);
yPosition+=7;
doc.text("Individual Service Connection Deduction - Rs. 152.92/- ",50,yPosition);
yPosition+=7;
doc.text("Placed to Divisional Manager, BHND for your information and necessary action please. ",50,yPosition);
yPosition+=7;





  // **Signature Section**
  yPosition  += 35;
  doc.text("D.E & Station Manager", 150, yPosition);
  doc.text("Minakhan CCC", 155, yPosition + 5);

  doc.addPage("a4", "portrait");
  doc.addImage(billTemplate, "JPEG", 0, 0, 210, 297); // **Set image as full page background**

  // **Overlay Text Fields**
  doc.setFontSize(9);
  doc.setTextColor(0, 0, 0); // Black text

  // **PO Number & Date**
  doc.text(data?.poNumber || "5300882750", 118, 96.3); // Adjust X, Y for alignment
  doc.text(formatDate(data?.poDate) || "11.09.2024", 156.5, 96.3);

  // **Work Order Number & Date**
  //wrapText(doc,"1638057/63212/567664/1636229/1624929/1624929/1624929/1624929/1624929/1624929/1624929/1624929/1624929",107.5,180.5, 114.5,3.3);
  //wrapText(doc,"26.09.24/27.09.24/28.09.24/29.09.24/30.09.24/30.09.24/30.09.24/30.09.24/30.09.24/30.09.24/30.09.24/30.09.24", 107.5,173.5, 129.5,3.3);

  wrapText(doc, orderNumbers, 107.5, 180.5, 114.5, 3.3);
wrapText(doc, orderDates, 107.5, 173.5, 129.5, 3.3);


  doc.text(`${data.meterCount}`,94,161.5);
  doc.text(`${(data.meterCount * 2041.24).toFixed(2)}`, 155,161.5)


  doc.text(`${data.totalSERLMeter} Meter`,90,166.5);
  doc.text(`${(data.totalSERLMeter * 7.88).toFixed(2)}`, 155,166.5)

  doc.text(`${((data.meterCount * 2041.24) + (data.totalSERLMeter * 7.88)).toFixed(2)}`, 155,171.5)

  doc.text(`${(((data.meterCount * 2041.24) + (data.totalSERLMeter * 7.88))*0.09).toFixed(2)}`, 155,181.5)
  doc.text(`${(((data.meterCount * 2041.24) + (data.totalSERLMeter * 7.88))*0.09).toFixed(2)}`, 155,186.5)

  let total = (((((data.meterCount * 2041.24) + (data.totalSERLMeter * 7.88))*0.09)*2)+(data.meterCount * 2041.24) + (data.totalSERLMeter * 7.88)).toFixed(2);

  doc.text(`${total}`, 155,191.5)

  const totalWords = numToWords(total);

  wrapText(doc,totalWords,76,180,196.5,3);


  doc.addPage("a4", "landscape"); 
  doc.addImage(mcsTemplate, "JPEG", 0, 0, 297, 210);

  doc.setFontSize(10)
  doc.text(data?.poNumber || "5300882750", 27, 31.5); // Adjust X, Y for alignment
  doc.text(formatDate(data?.poDate) || "11.09.2024", 92, 31.5);

  doc.setFontSize(9)
  doc.text("Work Order:",128,68)
  wrapText(doc,orderNumbers,128,168,71,3)
  doc.text("Work Order Date:",128,93)
  wrapText(doc,orderDates,128,168,96.4,3)


  doc.text(`${data.meterCount}`,159.5,25.8);

  doc.text(`${data.allMeterCount}`,112,68)
  doc.text(`${data.meterCount}`,88,68);
  doc.text(`${data.allMeterCount}`,182,68)
  doc.text(`${data.allMeterCount - data.meterCount}`,207,68)
  doc.text(`${data?.balance || "Nill"}`,223.5,68)


  // **Save the PDF**
  doc.save(`Note_Sheet_${formattedDate}.pdf`);

  console.log(data.meterCount);
};





  return (
    <div className="total-container">
       <DashboardHeader/>
    <div className="nsc-container">
      <h1 className="title">NSC Billing System</h1>
      <div className="billing-card">
        <div className="grid-container">
          <NoteSheet
            data={data}
            setData={setData}
            showForm={showNoteSheetForm}
            setShowForm={setShowNoteSheetForm}
          />
          
          <MaterialConsumption
            data={data}
            setData={setData}
            showForm={showMaterialConsumptionForm}
            setShowForm={setShowMaterialConsumptionForm}
            updateMeterCount={updateMeterCount}
          />
          <ActualBill selected={selectedComponents.actualBill} />
          <BillReverseCopy selected={selectedComponents.billReverseCopy} />
        </div>

        {/* Buttons for Save, Reset, and Generate PDF */}
        <div className="button-container">
          <button className="save-btn" onClick={saveForms}>Save</button>
          <button className="reset-btn" onClick={resetForms}>Reset</button>
       
    <button className="generate-btn" onClick={()=>generateNoteSheetPDF(data)}>Download NSC Bill</button>

        </div>

        {/* Show PDF Viewer for preview */}
        {showBill && (
          <div className="pdf-preview">
            <h3>Invoice Preview</h3>
            <PDFViewer width="100%" height={500}>
              <BillTemplate data={data} />
            </PDFViewer>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default NSCBilling;
