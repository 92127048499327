import React from 'react';
import DashboardHeader from './DashboardHeader';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../UserContext';

const Dashboard = () => {
  const navigate = useNavigate();
  const { authenticated, isAdmin,userDetails} = React.useContext(UserContext);


  const redirectPayroll = () => {
    navigate('/payroll');
  };

  const redirectProfitManagement = () => {
    navigate('/profit-management');
  };

  const redirectInvoices = () => {
    navigate('/invoice');
  };

  const redirectCustomers = () => {
    navigate('/customers');
  };

  const redirectPriceList = () => {
    navigate('/pricelist');
  };


  const redirectInvoiceSearch = () => {
    navigate('/searchInvoice');
  };

  const redirectAssignWork = () => {
    navigate('/assignwork');
  };


  const redirectPOChecker = () => {
    navigate('/purchase-order');
  };

  const redirectNSCBilling = () => {
    navigate('/billing/NSCBilling');
  }

  return (
    <div>
      <DashboardHeader className="dashboard-header"/>
      {isAdmin ? (<>  <div className="button-container">
        <div onClick={redirectPayroll} className="system-button">
          <h3>Payroll System</h3>
        </div>
        <div onClick={redirectCustomers} className="system-button">
          <h3>Customer Payments</h3>
        </div>
        <div onClick={redirectAssignWork} className="system-button">
          <h3>Assign Work</h3>
        </div>
        <div onClick={redirectNSCBilling} className="system-button">
          <h3>NSC Billing</h3>
        </div>
        <div onClick={redirectProfitManagement} className="system-button">
          <h3>Profit Management</h3>
        </div>
        <div onClick={redirectInvoices} className="system-button">
          <h3>Generate Invoice</h3>
        </div>
        <div onClick={redirectPOChecker} className="system-button">
          <h3>PO Checker</h3>
        </div>
        <div onClick={redirectPriceList} className="system-button">
          <h3>Item Details</h3>
        </div>
        <div onClick={redirectInvoiceSearch} className="system-button">
          <h3>Invoice Search</h3>
        </div>
      </div></>) : (<>
        <div className="button-container">
        <div onClick={redirectInvoices} className="system-button">
          <h3>Generate Invoice</h3>
        </div>
        <div onClick={redirectNSCBilling} className="system-button">
          <h3>NSC Billing</h3>
        </div>
        <div onClick={redirectPOChecker} className="system-button">
          <h3>PO Details</h3>
        </div>
        <div onClick={redirectInvoiceSearch} className="system-button">
          <h3>Invoice Search</h3>
        </div>
        {userDetails.user.email === 'nasrul@apenterprise.com' && (
            <div onClick={redirectPriceList} className="system-button">
              <h3>Item Details</h3>
            </div>
          )}
      </div>
      </>)}
    
    </div>
  );
};

export default Dashboard;
