import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: "bold",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
  },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid black",
    padding: 5,
  },
  cell: {
    flex: 1,
    textAlign: "center",
  },
});

const BillTemplate = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <Text style={styles.title}>A.P. ENTERPRISE - Invoice</Text>

      {/* Invoice Details */}
      <View style={styles.section}>
        <Text style={styles.label}>Vendor Code:</Text> <Text>512730</Text>
        <Text style={styles.label}>Invoice No:</Text> <Text>APE/24-25/XXXX</Text>
        <Text style={styles.label}>Date:</Text> <Text>{new Date().toLocaleDateString()}</Text>
      </View>

      {/* Buyer Details */}
      <View style={styles.section}>
        <Text style={styles.label}>Buyer:</Text>
        <Text>West Bengal State Electricity Distribution Company Limited</Text>
        <Text>Minakhan CCC</Text>
      </View>

      {/* P.O Details */}
      <View style={styles.section}>
        <Text style={styles.label}>P.O Number:</Text> <Text>{data.poNumber || "N/A"}</Text>
        <Text style={styles.label}>P.O Date:</Text> <Text>{data.poDate || "N/A"}</Text>
      </View>

      {/* Work Order Details */}
      <View style={styles.section}>
        <Text style={styles.label}>Work Orders:</Text>
        {data.workOrders.map((order, index) => (
          <Text key={index}>
            {index + 1}. {order.workOrderNumber} - {order.workOrderDate}
          </Text>
        ))}
      </View>

      {/* Material Consumption */}
      <View style={styles.section}>
        <Text style={styles.label}>Material Consumption:</Text>
        <Text>{data.meterDetails ? "Attached as separate file" : "No file uploaded"}</Text>
      </View>

      {/* Total Amount */}
      <View style={styles.section}>
        <Text style={styles.label}>Total Bill Amount:</Text>
        <Text>₹1,28,977.78 (Including GST)</Text>
        <Text style={styles.label}>Deductions:</Text>
        <Text>₹152.92</Text>
      </View>

      {/* Footer */}
      <View style={styles.section}>
        <Text>Certified by: WBSEDCL Minakhan CCC</Text>
        <Text>Signature: ________________________</Text>
      </View>
    </Page>
  </Document>
);

export default BillTemplate;
