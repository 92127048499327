import React from "react";
import "./ComponentStyles.css"; // Import shared styles

const BillReverseCopy = ({ selected }) => {
  return (
    <div className="component-card">
      <label className="checkbox-label">
        <input type="checkbox" disabled checked={selected} />
        <span></span> Bill Certificate
      </label>
    </div>
  );
};

export default BillReverseCopy;
